import { Component } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment.prod";
import { NgxSpinnerService } from "ngx-spinner";
import { intervalToDuration, formatDuration } from "date-fns";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"]
})
export class AppComponent {
  title = "reader";
  img = new Image();
  image_loaded = false;
  image_name = "";
  colors = { icr: "blue", iwr: "red", date: "green" };

  engineResults;
  date;
  date_acc;
  icr;
  icr_acc;
  iwr;
  iwr_2;
  iwr_acc;
  icr_iwr;
  eq;
  val_date;


  constructor(private http: HttpClient, private spinner: NgxSpinnerService) {}

  upload(event: any) {
    const serverHost = environment.server;
    console.log("server", serverHost)
    this.engineResults = {};
    var that = this;
    this.img.onload = function() {
      var c = <HTMLCanvasElement>document.getElementById("image");
      var ctx = c.getContext("2d");
      ctx.canvas.width = that.img.width;
      ctx.canvas.height = that.img.height;
      ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

      ctx.drawImage(that.img, 0, 0);
      that.image_loaded = true;
      var image = event.target.files[0];
      that.image_name = image.name;
      let formData = new FormData();
      formData.append("file", image, image.name);

      that.spinner.show();
      that.http
        .post(serverHost + "", formData)
        .subscribe(
          result => {
            console.log(result);
            result[image.name]
            that.spinner.hide();
          that.draw(result);
          that.engineResults = result[that.image_name];
          console.log("response", that.engineResults)
          that.date = that.engineResults["date"]["label_dt"][0][0] || ""
          that.date_acc = parseFloat(that.engineResults["date"]["label_dt"][0][1] || 0.15 ) * 100
          that.date_acc = that.date_acc.toFixed()
          that.icr = that.engineResults["icr"]["label_dt"][0][0] || ""
          that.icr = that.icr.substring(0, that.icr.length -1)
          that.icr_acc = parseFloat(that.engineResults["icr"]["label_dt"][0][1] || 0.1 ) * 100
          that.icr_acc= that.icr_acc.toFixed()
          that.iwr = that.engineResults["iwr"]["label_dt"][0][0] || ""
          that.iwr_acc = parseFloat(that.engineResults["iwr"]['label_dt'][0][1] || 0.2  ) * 100 
          console.log("iwr_acc", parseFloat(that.engineResults["iwr"]['label_dt'][0][1] || 0.2) * 100)
          that.iwr_acc= that.iwr_acc.toFixed()
          that.icr_iwr = that.engineResults["iwr"]["label_icr2iwr_dt"][0]|| ""
          if (that.engineResults["iwr"]["label_dt"].length  > 1){
            that.iwr_2 = that.engineResults["iwr"]["label_dt"][1][0] 
            console.log("1 iwr",that.iwr)
            console.log("2 iwr",that.iwr_2)
            if (that.iwr_2.endsWith("trente")) {
               that.iwr_2 = that.iwr_2.substring(0, that.iwr_2.length -7)
            }
            that.iwr = that.iwr + " " + that.iwr_2 + " franc"
            console.log("c", that.iwr)
          }else {
            if (that.iwr.endsWith("trente")) {
               that.iwr = that.iwr.substring(0, that.iwr.length -7)
            }


          }
          if (!that.iwr.endsWith("franc")) {
            that.iwr = that.iwr + " franc"
          }
          console.log("iwr",that.iwr)
          console.log("icr_iwr",that.icr_iwr)
          if (that.iwr === that.icr_iwr){
            that.eq = "OUI"
          }else {
            that.eq = "NON"
          }


          let d  = that.date.split("/")
          let eng_date = d[1] + "/" + d[0] + "/" + d[2]
          console.log("eng",eng_date)
          let current_date = new Date("02/02/2021")
          console.log("current",current_date)
          let check_date = new Date(eng_date)
          console.log("check",check_date)
          let a = intervalToDuration({ start:
            check_date,
            end: current_date})
          console.log("a",a)
          that.val_date = "OUI"
          if (a['years'] >= 3) {
            if ( (a['years'] === 3) && (a['months'] === 0 )  && (a['date'] <= 8)){
              that.val_date = "OUI"
            }
            else {
              that.val_date = "NON"
            }
          
          

          }

          },

          error => {
            console.log(error);
            that.spinner.hide();
            alert("Cannot upload images to server");
          }
        );
    };

    this.img.src = URL.createObjectURL(event.target.files[0]);
  }

  transcribe() {
    const serverHost = environment.server;
    var that = this;
    var data = { images: [this.image_name] };
    this.spinner.show();
    this.http
      .post(serverHost + "transcribe?date=" + new Date().getTime(), data)
      .subscribe(
        result => {
          console.log(result["results"][that.image_name]);
          that.engineResults = result["results"][that.image_name];
          that.spinner.hide();
          that.draw(result["results"]);
        },
        error => {
          console.log(error);
          that.spinner.hide();
        }
      );
  }

  private draw(results) {
    var c = <HTMLCanvasElement>document.getElementById("image");
    var ctx = c.getContext("2d");

    ctx.setLineDash([]);
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    ctx.drawImage(this.img, 0, 0);
    for (var i in results[this.image_name]) {
      ctx.strokeStyle = this.colors[i];
      ctx.fillStyle = this.colors[i];

      let bboxes = results[this.image_name][i]["bbox_dt"];
      for (var j in bboxes) {
        ctx.strokeRect(
          bboxes[j][0][0],
          bboxes[j][0][1],
          bboxes[j][0][2],
          bboxes[j][0][3]
        );
      }
    }
  }

  getKeys(map) {
    return Array.from(map.keys());
  }
}
